import React from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'
// import Introduction from "../components/Service/Introduction"
import ServiceContent from "../components/Service/ServiceContent"
import ServicesList from "../components/Service/ServicesList"
import Breadcrumb from "../components/Global/Breadcrumb"
import Faqs from "../components/Global/Faqs"



function ExaminationTemplate(props) {
    let data = props.data.wpgraphql;

    function getBreadcrumb() {
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/"}`
        })
        bCrumb.push({
            text: data.project.projectCategories.edges[0].node.breadcrumbServicesAcf.breadcrumbName,
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : ""}/${data.project.projectCategories.edges[0].node.breadcrumbServicesAcf.breadcrumbSlug}/`
        })
        bCrumb.push({
            text: data.project.breadcrumbAcf.breadcrumbName,
        })
        return bCrumb;
    }

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://karanikolascon.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://karanikolascon.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <div>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}
                recentPosts = { data.posts }
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                metaData = {constructMetaData(data.project, props.pageContext.currentPage, props.pageContext.language)}
                services={props.pageContext.servicesCategories}
                projects={props.pageContext.projectsCategories}
                preHeader={data.template.contactInformationTemplate}
            >
                <div>

                    {data.project.breadcrumbAcf.breadcrumbName && (
                        <section>
                            <Breadcrumb list={getBreadcrumb()} image={data.project.breadcrumbAcf.image}  h1={data.project.breadcrumbAcf.breadcrumbName} />
                        </section>
                    )}
                    <section className="container flex flex-wrap m-auto justify-center md:my-10" style={{maxWidth: "1170px"}}>
                        <ServicesList list={data.projectCategories.edges} currentPage={data.project.uri} contact={data.template.contactInformationTemplate} />

                        <ServiceContent content={data.project.content} featured={data.project.servicesAcf.featuredImage} />
                    </section>
                    <section>
                        {(data.project.faqsAcf.faqs && data.project.faqsAcf.faqs.faqs && data.project.faqsAcf.faqs.faqs.length > 0) && (
                            <Faqs
                                data={data.project.faqsAcf.faqs}
                            />
                        )}
                    </section>
                </div>
            </Layout>
        </div>

    )
}

export default ExaminationTemplate

export const pageQuery = graphql`query GET_EXAMINATION($id: ID!, $language: WPGraphQL_LanguageCodeFilterEnum, $themeTemplateUri: ID!) {
  wpgraphql {
    project(id: $id) {
      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
      breadcrumbAcf {
        breadcrumbName
        image {
          sourceUrl
          altText
          imageFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
      projectCategories {
        edges {
          node {
            breadcrumbServicesAcf {
              breadcrumbName
              breadcrumbSlug
            }
          }
        }
      }
      title
      content
      uri
      servicesAcf {
        featuredImage {
          sourceUrl
          altText
          imageFile {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
      faqsAcf {
        faqs {
          title
          faqs {
            question
            answer
            visibleInSite
          }
        }
      }
    }
    projectCategories(where: {language: $language}) {
      edges {
        node {
          name
          projects(first: 200, where: {orderby: {field: MENU_ORDER, order: ASC}}) {
            edges {
              node {
                id
                uri
                title
              }
            }
          }
        }
      }
    }
    template(id: $themeTemplateUri, idType: SLUG) {
      contactInformationTemplate {
        telephoneNumber
        mobileNumber
        supportEmail
        hoursLabel
        hoursTitle
        form {
          title
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }

    
  }
}
`